<template>
    <div>
        <v-Mtel></v-Mtel>
        <v-Mheader></v-Mheader>
        <v-Mbanner :bannerObj="bannerObj"></v-Mbanner>
        <v-Mnews></v-Mnews>
        <v-Mbottom></v-Mbottom>
    </div>
</template>
<script>
import Mheader from '@/components/Mheader.vue'
import Mbanner from '@/components/Mbanner.vue'
import Mnews from '@/components/Mnews.vue'
import Mbottom from '@/components/Mbottom.vue'
import Mtel from '@/components/Mtel.vue'
export default {
    components: {
        'v-Mbanner': Mbanner,
        'v-Mnews': Mnews,
        'v-Mbottom': Mbottom,
        'v-Mheader': Mheader,
        'v-Mtel': Mtel
    },
    data() {
        return {
            bannerObj: {
                title: '新闻中心',
                desc: '行业资讯整合',
                culture: '让消防官兵少流血少流泪',
                img: require('@/assets/banner_03.png')
            }
        }
    }
}
</script>