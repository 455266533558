<template>
    <div class="wrap">
        <v-Title title="新闻中心" content="行业资讯"></v-Title>
        <ul class="news">
            <el-link
                class="item"
                v-for="(item,index) in items"
                :key="index"
                :href="item.href"
                target="_blank"
            >
                <el-row :gutter="8">
                    <el-col :span="8" class="img">
                        <el-image style="width: 100%; height: 80px" :src="item.img" fit="fill"></el-image>
                    </el-col>
                    <el-col :span="16" class="text">
                        <span class="title">{{item.title}}</span>
                        <span class="content">{{item.content}}</span>
                    </el-col>
                </el-row>
            </el-link>
        </ul>
    </div>
</template>
<script>
import Title from './Mtitle'
export default {
    components: {
        'v-Title': Title
    },
    data() {
        return {
            items: [
                {
                    href: 'https://mp.weixin.qq.com/s/shbtzp5tW_Ab2GaPuQ62pA',
                    img: require('@/assets/news_1.png'),
                    title:
                        '第六届互联网大会嘉兴企业发布数字经济新成果（第一期）',
                    content:
                        '数字技术驱动下的智慧城市——“智慧城市”将城市信息化建设进行统一接入与管理，实现跨部门、多专业、综合性的系统集成，实现更全面的互联互通、更有效的交换共享、更协作的关联应用，将大规模的人类活动与城市中各行业的运行数据交织在一起，加速了由“数字城市建设”到“智慧城市”的转变。其中，数字技术将扮演极为重要的角色。在城市智慧经济体系中，工业互联网基础条件之一就是数字化工厂。  数字化工厂，智慧园区，智慧城市三级数字化网络的建设，从微观上解决了企业的生产模式和管理模式的重大转变，为企业步入高质量发展奠定了基础；从宏观上实现了工业互联和生产资源的合理调配，为中国工业体系走向有序、健康，为国家繁荣、富强和社会安定保驾护航。'
                },
                {
                    href:
                        'https://appexternal.cnjxol.com/webDetails/news?id=811033&tenantId=1&from=timeline&isappinstalled=0',
                    img: require('@/assets/news_2.jpeg'),
                    title:
                        '4个项目签约、“黑科技”引来“八方客”,乌镇峰会上南湖区收获丰硕!',
                    content:
                        '第六届世界互联网大会•乌镇峰会迎来八方宾客。在这次世界聚焦的盛会上，南湖区收获颇丰。截至10月21日下午5时，南湖区已在大会期间签约4个项目，总投资达19亿元；“黑科技”惊艳四座，引来专家、行业上下游企业关注，现场达成合作意向。'
                },
                {
                    href:
                        'https://appexternal.cnjxol.com/webDetails/news?id=810507&tenantId=1&from=singlemessage',
                    img: require('@/assets/news_3.jpeg'),
                    title:
                        '会按摩的柔性机器人、上过央视的“搜救神器”……来围观南湖区即将亮相“互联网之光”的黑科技',
                    content:
                        '作为世界互联网大会的重要组成部分，每年的“互联网之光”博览会都会有一批酷炫的互联网新产品、新技术在此展示、发布，今年也不例外。第六届世界互联网大会将于10月20日至22日在浙江乌镇举行，南湖区一批科技企业、创新平台已经准备就绪，将集体亮相，各展所长，向世界展现南湖区互联网创新平台及企业的尖端技术。'
                }
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    .news {
        list-style: none;
        .item {
            padding: 6px;
            box-sizing: border-box;
            background: rgba(255, 255, 255, 1);
            margin-bottom: 12px;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 10px 20px 0px rgba(176, 176, 176, 0.1);
            }
            &:last-child {
                margin-bottom: 0;
            }
            .text {
                display: flex;
                flex-direction: column;
                .title {
                    font-size: 14px;
                    font-weight: bold;
                    color: rgba(51, 51, 51, 1);
                }
                .content {
                    margin-top: 8px;
                    font-size: 12px;
                    color: rgba(153, 153, 153, 1);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
</style>